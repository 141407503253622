import { ChartWrapperStyled } from '@component/charts/graph.styled';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, Tooltip, XAxis, YAxis } from '@component/charts/recharts-wrapper';
import { CHART_COLORS } from '@component/charts/utils';
import { headerId } from '@components/org.menu/client-header.utils';
import { toBoolean } from '@global/utils/boolean/to-boolean';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { ChatfuelBlockNames } from '@global/utils/chat/chatfuel-blocks-types';
import {
  SleepDiaryTag,
  SleepDiaryTechnique,
  getEmojiAndNameForSleepDiaryTag,
  getEmojiAndNameForSleepDiaryTechnique,
} from '@global/utils/domain/entities';
import {
  SleepTagAndTechniqueSummary,
  getBestRelaxCognitiveTechniqueAndWorstTag,
  getSummaryKeysWithOccurence,
  mapFactorToName,
} from '@global/utils/domain/tag-and-technique-comparator';
import { Query_Root } from '@global/utils/remote-graphql-types';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { HackOverlay } from '@lp-root/src/components/mol.hack-overlay/hack-overlay.component';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { SleepDiaryWithMedicineQuery } from '@lp-root/src/data/graphql/hasura/sleep-diary-with-medicine.query.hasura';
import { buildChatfuelBroadcastRequest } from '@lp-root/src/data/request-utils/redirect.request';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, BrandColor, Col, FaIcon, Grid, H1, H2, LightColor, Row, Separator } from '@web/atomic';
import { StickButtonWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { EnhancedSelect } from '@web/atomic/legacy/atm.enhanced-select/enhanced-select.component';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { ShimmerText } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { Form } from '@web/atomic/legacy/obj.form';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useClientRect } from '@web/atomic/obj.custom-hooks/client-rect.hook';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { useMobile } from '@web/atomic/obj.custom-hooks/mobile.hook';
import { RelativeWrapper } from '@web/atomic/obj.wrappers';
import { TagAndTechniqueComparatorTable } from '@web/atomic/org.tag-and-technique-comparator-table/tag-and-technique-comparator-table.component';
import { useSleepTagAndTechniqueSummary } from '@web/atomic/org.tag-and-technique-comparator-table/tag-and-technique-comparator-table.utils';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { hasWindow } from '@web/utils/platform';
import { ScrollElement, getTopElementHeight, scrollToElement } from '@web/utils/scroll';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import QueryString from 'querystringify';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { JsonObject } from 'type-fest';

interface ComparadorDeTecnicasFatoresPageQueryParams {
  redirect?: ChatfuelBlockNames;

  // tags
  alcohol?: 'true' | 'false';
  bathroom?: 'true' | 'false';
  caffeine?: 'true' | 'false';
  exercise?: 'true' | 'false';
  light?: 'true' | 'false';
  meal?: 'true' | 'false';
  medicine?: 'true' | 'false';
  nap?: 'true' | 'false';
  nicotine?: 'true' | 'false';
  noise?: 'true' | 'false';
  pain?: 'true' | 'false';
  partner?: 'true' | 'false';
  temperature?: 'true' | 'false';
  dream?: 'true' | 'false';

  // techniques
  autogenic_training?: 'true' | 'false';
  behavior_activation?: 'true' | 'false';
  challenge_catastrophic_thinking?: 'true' | 'false';
  deep_breath?: 'true' | 'false';
  gratitude?: 'true' | 'false';
  imagery?: 'true' | 'false';
  light_therapy?: 'true' | 'false';
  meditation?: 'true' | 'false';
  paradoxical_intention?: 'true' | 'false';
  parking_lot?: 'true' | 'false';
  pmr?: 'true' | 'false';
  stimulus_control?: 'true' | 'false';
  thought_block?: 'true' | 'false';
}

const ComparadorDeTecnicasFatoresPage: React.FunctionComponent<PageProps> = (props) => {
  const { data, error, loading } = useQueryCustom(SleepDiaryWithMedicineQuery);

  const summary = useSleepTagAndTechniqueSummary(data);
  const summaryKeys: (keyof SleepTagAndTechniqueSummary)[] = getSummaryKeysWithOccurence(summary);

  const [selectedKeys, setSelectedKeys] = useState(['all'] as (keyof SleepTagAndTechniqueSummary)[]);

  const params: ComparadorDeTecnicasFatoresPageQueryParams = QueryString.parse(hasWindow() ? window.location.search : '');
  useQueryParamsProcessing(params, setSelectedKeys);

  const handleSelectChange = (selection: { value: keyof SleepTagAndTechniqueSummary }[]) => {
    setSelectedKeys(selection ? selection.map((item) => item.value) : []);
  };

  const tableKeys: (keyof SleepTagAndTechniqueSummary)[] = selectedKeys.slice().reverse();
  tableKeys.unshift('all');

  const [handleClose, closeLoading] = useClose(summary, data, params.redirect);

  const [rect, titleRef] = useClientRect();

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Comparador de técnicas e tags do diário do sono',
          description: 'Descubra como cada tag/técnica impacta seu sono.',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12}>
            <H1 ref={titleRef}>Comparador</H1>
          </Col>
        </Row>
        <LoadingState loading={loading} error={!!error} data={!!summary}>
          {/* TODO: UI - ajustar loading */}
          <LoadingState.Shimmer>
            <Row mt mb>
              <Col xs={12} sm={12} md={7} lg={7}>
                <H2>
                  <ShimmerText />
                </H2>
                <Body>
                  <ShimmerText />
                  <br />
                  <ShimmerText />
                  <br />
                  <ShimmerText />
                </Body>
              </Col>
            </Row>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <Placeholder
              icon={<FaIcon.FlashAlert size="9x" />}
              title={'Erro ao pegar seus dados'}
              description="Tente novamente mais tarde"
            />
          </LoadingState.Error>
          <LoadingState.NoData>
            <Placeholder
              icon={<FaIcon.Table size="9x" />}
              title={'Seu diário está em branco'}
              description={`Quando você preencher seu diário, os seus dados poderão ser vistos nessa tela, ok? Preencha 1 vez e veja como fica aqui!`}
            >
              <GatsbyButton onClick={handleClose}> Combinado! </GatsbyButton>
            </Placeholder>
          </LoadingState.NoData>
          {summaryKeys && summaryKeys.length === 0 && (
            <Placeholder
              icon={<FaIcon.Table size="9x" />}
              title={'Ainda não temos dados para te mostrar'}
              description={`Preencha seu diário indicando fatores (luz, barulho etc) que interferiram no seu sono e tais dados poderão ser analisados aqui.`}
            >
              <GatsbyButton onClick={handleClose}> Combinado! </GatsbyButton>
            </Placeholder>
          )}

          {summaryKeys && summaryKeys.length > 0 && (
            <Row mb>
              <Col xs={12}>
                <H2>Como seu sono foi influenciado?</H2>
              </Col>
            </Row>
          )}

          <TagAndTechniquesSelectRow summaryKeys={summaryKeys} selectedKeys={selectedKeys} onSelectChange={handleSelectChange} />

          {summary && tableKeys && (
            <Row mb>
              <Col xs={12}>
                <Separator />
                <TagAndTechniqueComparatorTable summary={summary} tableKeys={tableKeys} />
              </Col>
            </Row>
          )}

          {summary && (
            <Row mt mb>
              <Col xs={12}>
                <TechniqueComparatorGraph width={rect && rect.width} summary={summary} />
              </Col>
            </Row>
          )}

          {summary && (
            <Row mt mb>
              <Col xs={12}>
                <TagComparatorGraph width={rect && rect.width} summary={summary} />
              </Col>
            </Row>
          )}

          {!loading && (
            <StickButtonWrapper>
              <GatsbyButton onClick={handleClose} loading={closeLoading} expanded>
                Fechar
              </GatsbyButton>
            </StickButtonWrapper>
          )}
        </LoadingState>
      </Grid>
    </WebviewLayout>
  );
};

export const query = graphql`
  query ComparadorDeTecnicasFatoresPage {
    site {
      ...SiteUrl
    }
  }
`;

export default ComparadorDeTecnicasFatoresPage;

// ////////////////////////////////////////////////////////////////////////////////////////////////////
interface TagAndTechniquesSelectRowProps {
  summaryKeys: (keyof SleepTagAndTechniqueSummary)[];
  selectedKeys: (keyof SleepTagAndTechniqueSummary)[];
  onSelectChange: (selection: { value: keyof SleepTagAndTechniqueSummary }[]) => void;
}

const TagAndTechniquesSelectRow: FunctionComponent<TagAndTechniquesSelectRowProps> = (props) => {
  const selectKeys: (keyof SleepTagAndTechniqueSummary)[] = props.summaryKeys && props.summaryKeys.filter((key) => key !== 'all');

  const mobile = useMobile();

  const select = useRef<EnhancedSelect>();
  const handleHackOverlayClick = () => {
    select.current.focus();
  };

  const mapToEnhancedSelect = (item: keyof SleepTagAndTechniqueSummary) => ({ label: mapFactorToName(item), value: item });

  const selectScrollId = 'select_id';
  const handleSelectFocus = () => {
    scrollToElement(selectScrollId, { top: getTopElementHeight(headerId) });
  };

  if (!selectKeys || selectKeys.length === 0) {
    return null;
  }
  return (
    <Row mb>
      <Col xs={12}>
        <Body>Veja abaixo como seu sono foi afetado por algo que você fez no dia anterior ou que aconteceu durante a noite</Body>
        <Separator />

        <ScrollElement name={selectScrollId} />
        <RelativeWrapper zIndex={2}>
          <ForcedFade show={true}>
            <Form>
              <Form.Field name="select" initialValue={props.selectedKeys.map(mapToEnhancedSelect)} onValueChange={props.onSelectChange}>
                <EnhancedSelect
                  placeholder="Selecione o que quer comparar 👉"
                  ref={select}
                  onFocus={handleSelectFocus}
                  isMulti={true}
                  isSearchable={!mobile}
                  options={selectKeys.map(mapToEnhancedSelect)}
                />
              </Form.Field>
            </Form>
          </ForcedFade>
          <HackOverlay onClick={handleHackOverlayClick} />
        </RelativeWrapper>
      </Col>
    </Row>
  );
};

// ////////////////////////////////////////////////////////////////////////////////////////////////////
// TODO: REFACTOR - moveto another file
interface TechniqueComparatorGraphsProps {
  summary: SleepTagAndTechniqueSummary;
  width: number;
}

export const TechniqueComparatorGraph: React.FunctionComponent<TechniqueComparatorGraphsProps> = (props) => {
  if (!props.summary) {
    return null;
  }

  const labelName = 'nome';
  const labelQuantity = 'quantidade';

  const barData = Object.values(SleepDiaryTechnique)
    .map((item) => {
      const data = {};
      data[labelName] = getEmojiAndNameForSleepDiaryTechnique(item as SleepDiaryTechnique);
      data[labelQuantity] = props.summary[item] && props.summary[item].count;
      return data;
    })
    .filter((item) => item[labelQuantity] > 0)
    .sort((a, b) => b[labelQuantity] - a[labelQuantity]);

  if (barData.length === 0) {
    return null;
  }

  const getEmoji = (str: string) => str.split(' ')[0];
  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <>
          <H2 ref={ref}>Quais técnicas foram mais utilizadas? </H2>
          {inView && (
            <ChartWrapperStyled>
              <BarChart
                data={barData}
                height={200}
                width={props.width}
                // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
                margin={{ top: 0, right: 0, bottom: 0, left: -30 }}
              >
                <CartesianGrid stroke="#f5f5f5" />

                <XAxis dataKey={labelName} tickFormatter={getEmoji} label={{ position: 'insideBottomRight', offset: 0 }} />

                <YAxis label={{ value: labelQuantity, className: 'rechart-left-label', angle: -90, position: 'top' }} />
                <Bar dataKey={labelQuantity} fill={BrandColor.Hoki} />
                <Tooltip cursor={{ fill: LightColor.HighlightLight }} />
              </BarChart>
            </ChartWrapperStyled>
          )}
        </>
      )}
    </InView>
  );
};

// ////////////////////////////////////////////////////////////////////////////////////////////////////

// TODO: REFACTOR - moveto another file
interface TagComparatorGraphsProps {
  summary: SleepTagAndTechniqueSummary;
  width: number;
}

export const TagComparatorGraph: React.FunctionComponent<TagComparatorGraphsProps> = (props) => {
  if (!props.summary) {
    return null;
  }

  const labelName = 'name';
  const labelQuantity = 'value';

  const pieData = Object.values(SleepDiaryTag)
    .map((item) => {
      const data = {};
      data[labelName] = getEmojiAndNameForSleepDiaryTag(item as SleepDiaryTag);
      data[labelQuantity] = props.summary[item] && props.summary[item].count;
      return data;
    })
    .filter((item) => item[labelQuantity] > 0)
    .sort((a, b) => b[labelQuantity] - a[labelQuantity]);

  if (pieData.length === 0) {
    return null;
  }

  // const getEmoji = (str: string) => str.split(' ')[0]
  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => {
        return (
          <>
            <H2 ref={ref}>Quais fatores mais apareceram? </H2>
            {inView && (
              <PieChart height={400} width={props.width}>
                <Pie data={pieData} dataKey={labelQuantity} fill={BrandColor.Hoki} label>
                  {pieData.map((_entry, index) => (
                    <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip cursor={{ fill: LightColor.HighlightLight }} />
                <Legend align="left" verticalAlign="bottom" width={props.width} />
              </PieChart>
            )}
          </>
        );
      }}
    </InView>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

function useClose(
  summary: SleepTagAndTechniqueSummary,
  data: Query_Root,
  redirect?: ChatfuelBlockNames
): [() => Promise<JsonObject>, boolean] {
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const [post, { loading }] = usePostUsingMessenger<ChatfuelBroadcastRequest>({ onSuccess: close, url: PostUrl.RedirectUser });
  const handleClose = async () => {
    if (!summary) {
      close();
      return;
    }
    const analysis = getBestRelaxCognitiveTechniqueAndWorstTag(summary);

    const request = buildChatfuelBroadcastRequest(data.user[0].id, redirect, {
      'global-comparator_best_relax_name': analysis.bestRelax.name,
      'global-comparator_best_relax_efficiency': analysis.bestRelax.efficiency,
      'global-comparator_best_cognitive_name': analysis.bestCognitive.name,
      'global-comparator_best_cognitive_efficiency': analysis.bestCognitive.efficiency,
      'global-comparator_worst_tag_name': analysis.worstTag.name,
      'global-comparator_worst_tag_efficiency': analysis.worstTag.efficiency,
    });
    return post(request);
  };
  return [handleClose, loading || waitCloseLoading];
}

function useQueryParamsProcessing(
  params: ComparadorDeTecnicasFatoresPageQueryParams,
  setSelectedKeys: React.Dispatch<React.SetStateAction<(keyof SleepTagAndTechniqueSummary)[]>>
) {
  useEffect(() => {
    const newSelectedKeys = [
      toBoolean(params.alcohol) ? 'alcohol' : null,
      toBoolean(params.bathroom) ? 'bathroom' : null,
      toBoolean(params.caffeine) ? 'caffeine' : null,
      toBoolean(params.exercise) ? 'exercise' : null,
      toBoolean(params.light) ? 'light' : null,
      toBoolean(params.meal) ? 'meal' : null,
      toBoolean(params.medicine) ? 'medicine' : null,
      toBoolean(params.nap) ? 'nap' : null,
      toBoolean(params.nicotine) ? 'nicotine' : null,
      toBoolean(params.noise) ? 'noise' : null,
      toBoolean(params.pain) ? 'pain' : null,
      toBoolean(params.partner) ? 'partner' : null,
      toBoolean(params.temperature) ? 'temperature' : null,
      toBoolean(params.dream) ? 'dream' : null,
      toBoolean(params.autogenic_training) ? 'autogenic_training' : null,
      toBoolean(params.challenge_catastrophic_thinking) ? 'challenge_catastrophic_thinking' : null,
      toBoolean(params.behavior_activation) ? 'behavior_activation' : null,
      toBoolean(params.deep_breath) ? 'deep_breath' : null,
      toBoolean(params.gratitude) ? 'gratitude' : null,
      toBoolean(params.imagery) ? 'imagery' : null,
      toBoolean(params.light_therapy) ? 'light_therapy' : null,
      toBoolean(params.meditation) ? 'meditation' : null,
      toBoolean(params.paradoxical_intention) ? 'paradoxical_intention' : null,
      toBoolean(params.parking_lot) ? 'parking_lot' : null,
      toBoolean(params.pmr) ? 'pmr' : null,
      toBoolean(params.stimulus_control) ? 'stimulus_control' : null,
      toBoolean(params.thought_block) ? 'thought_block' : null,
    ].filter((item) => item);
    setSelectedKeys(newSelectedKeys as (keyof SleepTagAndTechniqueSummary)[]);
  }, [
    params.alcohol,
    params.autogenic_training,
    params.bathroom,
    params.caffeine,
    params.challenge_catastrophic_thinking,
    params.behavior_activation,
    params.deep_breath,
    params.exercise,
    params.gratitude,
    params.imagery,
    params.light,
    params.light_therapy,
    params.meal,
    params.medicine,
    params.meditation,
    params.nap,
    params.nicotine,
    params.noise,
    params.pain,
    params.paradoxical_intention,
    params.parking_lot,
    params.partner,
    params.pmr,
    params.stimulus_control,
    params.temperature,
    params.dream,
    params.thought_block,
    setSelectedKeys,
  ]);
}
