import gql from 'graphql-tag';

export const SleepDiaryWithMedicineQuery = gql`
  query SleepDiaryWithMedicineQuery {
    sleep_diary(order_by: { date: asc }) {
      tags {
        sleep_tag
      }
      sleep_diary_medicines {
        name
        dose
        id
      }
      autogenic_training
      behavior_activation
      comment
      concentration
      date
      deep_breath
      energy
      get_up
      go_bed
      go_sleep
      grade
      humor
      id
      imagery
      meditation
      paradoxical_intention
      gratitude
      light_therapy
      challenge_catastrophic_thinking
      parking_lot
      pmr
      relationships
      stimulus_control
      thought_block
      time_to_sleep
      timezone
      wake_up
      wake_up_count
      wake_up_duration
    }

    user {
      id
      first_name
      day_goal
      night_goal
    }
  }
`;
